

import React, { Fragment, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'

import Modal from "react-modal";

import {Routes, Route, useNavigate, useParams } from 'react-router-dom';
import moment from "moment";
import { getDefaultNormalizer } from "@testing-library/react";

import { CSVLink } from "react-csv";
import { FaStoreAltSlash } from 'react-icons/fa';

import './database.css';

function Database() {
  const params = useParams();
  const [showModal1, setShowModal1] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);
  const [option,setOption] = useState("");
  const user_id = localStorage.getItem("user_id")
  const admin = localStorage.getItem("admin");
  const [forms, setForm] = useState([]);
  const [staff, setStaff] = useState([]);
  const [result, setResult] = useState([]);
  const [firstnames,setFirstName] = useState([]);
  const [lastnames,setLastName] = useState([]);

  const customStyles = {
    content: {
      background: 'white',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '3% 5%',
    },

    overlay: {
      background: 'rgb(19, 19, 19, 0.1)',
    },
  };

    const navigate = useNavigate();

    const navigateDashboard = () => {
      navigate('/dashboard');
    };


   

    const filter = async () =>{
    
        // Declare variables
        var ddl = document.getElementById("field");
        var selectedValue = ddl.options[ddl.selectedIndex].value;
        var input = document.getElementById('search');
        var filter = input.value.toUpperCase();

           if (selectedValue == "Firstname")
          {
            console.log("here")
            for (var i = 0; i < staff.length; i++) {
              console.log("Hello")
              var a = staff[i].first_name;
         
              var tr = document.getElementById(staff[i].user_id)
              if (a.toUpperCase().indexOf(filter) > -1) {
             
               
                
                tr.style.display = "";
              } else {
                console.log(staff[i].user_id)

                tr.style.display = "none";
                
              }
            }
          }else if (selectedValue == "Lastname")
          { for (var i = 0; i < staff.length; i++) {
          
            var a = staff[i].last_name;
          
            var tr = document.getElementById(staff[i].user_id)
            if (a.toUpperCase().indexOf(filter) > -1) {
           
             
              
              tr.style.display = "";
            } else {
              console.log(staff[i].user_id)

              tr.style.display = "none";
              
            }
          }
          }
          

        
      
    }

    const getResult = async (id,form) =>{
   


      var jsonData2 = {};
        try {
          const response2 = await fetch(
            `${localStorage.getItem("url")}/api/CompletedFormsResult/${params.user}/${params.induction}`
          );
          jsonData2 = await response2.json();
          
        } catch (err) {
         
          console.log(err.message);
        }
        console.log(jsonData2)
       setResult(jsonData2);
       setShowModal2(true)
       
    
      
    };

    const getForms = async (id) =>{
      
      
      var jsonData2 = {};
        try {
          const response2 = await fetch(
            `${localStorage.getItem("url")}/api/UsersCompletedForms/${params.user}`
          );
          jsonData2 = await response2.json();
          
        } catch (err) {
         
          console.log(err.message);
        }
        console.log(jsonData2)
        setForm(jsonData2);
        setFirstName(jsonData2.first_name);
       setLastName(jsonData2.last_name);
    
      
    };

   
    const getStaff = async () =>{
     
      var jsonData2 = {};
        try {
          const response2 = await fetch(
            `${localStorage.getItem("url")}/api/staff`
          );
          jsonData2 = await response2.json();
          
        } catch (err) {
         
          console.log(err.message);
        }
    
        setStaff(jsonData2)
  
       console.log(document.getElementById('3').attributes.value.value)
    
      
    };

    

    const changeStatus = async (value,id) => {
      const body = {id};
      console.log(value)
      if(value == 1){

     
      
      try {
   
        const response = await fetch(
          `${localStorage.getItem("url")}/api/disableAccount`,
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(body),
          }
        );
  
        response.json().then((result) => {
          if (result == "failed") {
         
      
            return window.alert("Failed to disable account");
          } else {
            window.location = "./Database"
           return window.alert("User has been de-activated");
           

          
          }
        });
      } catch (err) {
        console.log(err.message);
      }
    }else{

      try {
   
        const response = await fetch(
          `${localStorage.getItem("url")}/api/enableAccount`,
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(body),
          }
        );
  
 
        response.json().then((result) => {
          if (result == "failed") {
         
     
            window.alert("Failed to enable account");
          } else {
            window.location = "./Database"
           window.alert("User has been reactivated");
           

           
          }
        });
      } catch (err) {
        console.log(err.message);
      }

    }
    };

    const enable = async (id) => {
      const body = {id};
  
      try {
   
        const response = await fetch(
          `${localStorage.getItem("url")}/api/enableAccount`,
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(body),
          }
        );
  
 
        response.json().then((result) => {
          if (result == "failed") {
         
     
            window.alert("Failed to enable account");
          } else {
            window.location = "./Database"
           window.alert("User has been reactivated");
           

           
          }
        });
      } catch (err) {
        console.log(err.message);
      }

      
    };
    
      
  

  

  useEffect(()  =>  {
    if(admin == 1){
      getStaff();
      getForms();
      getResult();
     
   
    }else{
      window.location = "./Dashboard"
    }

   

    
        }, []);

        
        const getUser = async (id) => {
     

          console.log(id);
          navigate(`${id}`)
         
         
        
      }

  return (
    <div className="database_container">
      <div className="database_header">
        
        {forms.length 
                    ?
                    <h1>{forms[0].first_name + " " + forms[0].last_name}</h1>

                    :
                    <h1>loading...</h1>
        }
        
      </div>


      <div className='database_content'>
        <div className="database_table">
        <table className="styled-table test">
          <tr>
          <th>Form</th>
            <th>Question</th>
            <th>Answer</th>
            <th>Date</th>
           
          </tr>
              {result.map((r) => (
               
              
                <tr>
                  <td>{r.name}</td>
                  <td>{r.question_text}</td>
           
                  <td>{r.answer_text}</td>
                  <td className='column_date'>{moment(r.date).format("DD-MM-YYYY")}</td>
                



                </tr>
                
                   ))}
              
              </table>
        </div>
      </div>
      
    

      
    </div>
  )
}

export default Database;

      {/* <label className="searchlabel" for="field">Search by:</label>
    
<select className="searchselect" name="field" id="field">
  <option value="Firstname">First name</option>
  <option value="Lastname">Last name</option>
  <option value="Form">Form name</option>
  <option value="Date">Date</option>
</select>
      <input className="searchinput" placeholder='search' id="search" onKeyUp={filter}></input> */}

        {/*<form className="database_table" id="all_input" >
        <div className="database_table">
          <table className="styled-table">
            <tr>
              <th>Name</th>
              <th>Active</th>
              <th >Disable Account</th>
              <th >Enable Account</th>
            
            </tr>

         
            {staff.map((r) => (
            <tr key={r.user_id}>
                <td><label><a onClick={(e) => getForms(r.user_id)} href={'#'+r.user_id}>{r.first_name} {r.last_name}</a> </label></td>
                <td>{r.active == 1 ? 'yes' : 'no'}</td>
                <td>
                <button  onClick={(e) => disable(r.user_id)}>Disable</button>
                  </td>
              <td>
              <button  onClick={(e) => enable(r.user_id)}>Enable</button>
              </td>
              </tr>
                
              ))} 
          </table>
        </div>
                 
         
        </form>
    
      </div>
      <div className="contactin_back">
        <button className="contact_back-button"  onClick={navigateDashboard}>
          <span  className="button_text">back</span>
        </button>
      </div>
      {staff.map((r) => (
        <div id={r.completed_form_id} class="overlay3">
              <div class="popup">
                <h2>{r.first_name} {r.last_name}</h2>
                <a class="close" href="#">&times;</a>
              <div class="content">
                <table className="overlaytable">
                <tr key={r.completed_form_id}>
                    
            
                    <th>Form</th>
                    <th>Question</th>
                    <th>Answer</th>
                    <th>Date</th>
                
                  </tr>
                  {result.map((s) => (
                    
                
                    <tr>
                      <td>{s.name}</td>
                      <td>{s.question_text}</td>
                      <td>{s.answer_text}</td>
                  
                      <td>{moment(s.date).format("DD-MM-YYYY")}</td>
                    
                    </tr>
                
                      ))}
                </table>
              </div>
              </div>
            </div>
      ))}
      {staff.map((r) => (
          <div id={r.user_id} class="overlay2" >
            <div class="popup">
              <h2>{r.first_name} {r.last_name}</h2>
              <a class="close" href="#">&times;</a>
            <div class="content">
            <table className="overlaytable">
            <tr key={r.completed_form_id}>
                
        
                <th>Form</th>
                <th>Completed</th>
                <th>Date</th>
             
              </tr>
              {forms.map((r) => (
               
              
                <tr>
                  <td><label><a onClick={(e) => getResult(r.user_id,r.completed_form_id,)} href={'#'+r.completed_form_id}>{r.name}</a></label></td>
           
                  <td>Yes</td>
                  <td>{moment(r.date).format("DD-MM-YYYY")}</td>
                



                </tr>
                
                   ))}
                   </table>
           
            </div>
            </div>
            </div> 
              ))}*/}  