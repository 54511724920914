import React, { useRef } from 'react';
import emailjs, { send } from '@emailjs/browser';


 

  function sendEmail2(formname,first_name,last_name,question1,answer_1,question2,answer_2,question3,answer_3,question4,answer_4,question5,answer_5,question6,answer_6,question7,answer_7,question8,answer_8,
    question9,answer_9,question10,answer_10,question11,answer_11,question12
   ,answer_12){
    const form = {"form_name" : formname, "firstname": first_name, "lastname" : last_name,
     "question1": question1,"answer1" : answer_1,"question2": question2,"answer2" : answer_2,"question3": question3,"answer3" : answer_3,"question4": question4,"answer4" : answer_4,
     "question5": question5, "answer5" : answer_5,"question6": question6,"answer6" : answer_6,
     "question7": question7,"answer7" : answer_7,"question8": question8,"answer8" : answer_8,"question9": question9,"answer9" : answer_9,"question10": question10,"answer10" : answer_10,
     "question11": question11,"answer11" : answer_11,"question12": question12,
   "answer12" : answer_12,}

    console.log(form);
    emailjs.send("service_eu8486s","template_pkuv5xt",form,"JIZfDG7e7bqsNSBz7").catch((err, message) => {
      console.log(err || message);
    })
    
    changeWindow();
     
   
    
    
  
  };

 function changeWindow(){
  window.alert("Form has been submitted");
  window.location = "./assessments";
 } 

  export default sendEmail2;
  


