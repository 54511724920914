import logo from './logo.svg';
import React, { Fragment } from "react";
import './App.css';
import Dashboard from './Pages/Dashboard';
import Assessments from './Pages/Assessments';
import Induction from './Pages/Induction/Induction';
import BodilyFluids from './Pages/Induction/BodilyFluids';
import BulkChillers from './Pages/Induction/BulkChillers';
import ForkliftReach from './Pages/Induction/ForkliftReach';
import ProductSpills from './Pages/Induction/ProductSpills';
import RecordingCredits from './Pages/Induction/RecordingCredits'
import SharedOp from './Pages/Induction/SharedOp'
import Errorpage from './Pages/Errorpage';
import Navbar from './Pages/Navbar'
import Database from './Pages/Database.js'
import User from './Pages/User.js'
import UserInduction from './Pages/UserInduction'
import Login_Navbar from './Pages/login/Login_Navbar'
import HealthSaftey from './Pages/Induction/HealthSaftey'
import Contact from './Pages/Contact/Contact'
import ContactInStore from './Pages/Contact/ContactInStore'
import ContactOutOfStore from './Pages/Contact/ContactOutOfStore'
import Footer from './Pages/footer.js'
import { BrowserRouter as Router, Routes,  Route } from 'react-router-dom'

import Login from "./Pages/login/login";
import Register from "./Pages/login/register";
import Forgotpassword from './Pages/login/Forgotpassword';
function App() {
  if (process.env.NODE_ENV === "production") {
    localStorage.setItem(
      "url",
      "https://paknsavetamateateam.herokuapp.com"
     
    );
  } else {
    localStorage.setItem("url", "http://localhost:3000");
  }

  if (localStorage.getItem("authenticated") === "true" && localStorage.getItem("first_time") == 0) {
    
      return (
        <Fragment>
        <Router >
          <Navbar />
          
          <Routes>
            <Route path='/' element={<Dashboard />} />
            <Route path='/assessments' element={<Assessments />} />
            <Route path='/induction' element={<Induction />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/contact-instore' element={<ContactInStore />} />
            <Route path='/contact-outofstore' element={<ContactOutOfStore />} />
            <Route path='/healthandsaftey' element={<HealthSaftey />} />
            <Route path='/induction-bodilyfluids' element={<BodilyFluids />} />
            <Route path='/induction-bulkchillers' element={<BulkChillers />} />
            <Route path='/induction-forkliftreachtrucks' element={<ForkliftReach />} />
            <Route path='/induction-productspills' element={<ProductSpills />} />
            <Route path='/induction-recordingcredits' element={<RecordingCredits />} />
            <Route path='/induction-sharedop' element={<SharedOp />} />
            <Route path="/register" element={<Register />} />
            <Route path="/database" element={<Database />} />
            <Route path='database/:user' element={<User />} />
            <Route path='database/:user/:induction' element={<UserInduction />} />
            <Route path='/forgotpassword' element={<Forgotpassword />} />
            <Route path='/login' element={<Login />} />
            <Route path='*' element={<Dashboard />} />
            
          </Routes>
        </Router>
    
        </Fragment>
          );
    }else{
      if(localStorage.getItem("first_time") == 1) {
        return (
          <Fragment>
          <Router >
         
            <Routes>
              <Route path='/' element={<Forgotpassword />} />
              <Route path='/forgotpassword' element={<Forgotpassword />} />
              <Route path='/login' element={<Login />} />
              <Route path='*' element={<Forgotpassword />} />
              
            </Routes>
          </Router>
      
          </Fragment>
        )
      
    
  
    

} else {
  return (
    <Fragment>
      <Router>
        <Routes>       
          <Route path="/" element={<Login />} />
          <Route path='/forgotpassword' element={<Forgotpassword />} />
          <Route path='*' element={<Login />} />
      </Routes>
      </Router>
    </Fragment>
  );
}
}
}

export default App;
