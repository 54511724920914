import React, { useState, useEffect, Fragment } from "react";
import md5 from "md5";
import sha1 from "sha1";
import './login.css';
import logo from '../../Assets/logohorizontal.png';
import emailjs from '@emailjs/browser';

const Forgotpassword = () => {
  const url = localStorage.getItem("url");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [vpassword, setVPassword] = useState("");
  const [re, setRe] = useState("HI");
  const [auth, setAuth] = useState("False");

 





  const onSubmitForm = async (e) => {
    e.preventDefault();

  
      if(password == vpassword){
        try {
        const hash_password = md5(sha1(vpassword));
        const body = { email, hash_password };
        const response = await fetch(`${localStorage.getItem("url")}/api/forgotPassword`, {
          method: "POST",
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           },
          body: JSON.stringify(body),
        });
        console.log(body);
        response.json().then((result) => {
          if (result == "failed") {
            localStorage.setItem("authenticated", "False");
  
            return alert("user with email " + email + " not found");
          } else {
      
            alert("Password for user " + email + " has been reset");
            localStorage.setItem("first_time" , 0);
            localStorage.setItem("authenticated" , "no");

            window.location = "/login";
          }
        });
      } catch (err) {
        console.log(err.message);
      }
      }else{
        window.alert("Passwords do not match")
      }
      
  };
  // const onSubmitForm = async () => {
  //   try {
  //     const response = await fetch("http://localhost:3000/login");
  //     const jsonData = await response.json();
  //     console.log(jsonData);
  //     setRe(jsonData);
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  // useEffect(() => {
  //   onSubmitForm();
  // }, []);



  return (
    <Fragment>
      <form id="login" onSubmit={onSubmitForm} className="login-container">
        <div id="form-body" className="login-content">
          <img src={logo} />
          <label>Reset Your Password</label>
          <div className="form-group">
            <label>Email:</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className="form-control"
              placeholder="Enter email"
            />
          </div>

          <div className="form-group">
            <label>New Password:</label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="form-control"
              placeholder="Enter password"
            />
          </div>

          <div className="form-group">
            <label>Confirm New Password:</label>
            <input
              value={vpassword}
              onChange={(e) => setVPassword(e.target.value)}
              type="password"
              className="form-control"
              placeholder="Enter password"
            />
          </div>

          {/* <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customCheck1"
              />
              <label className="custom-control-label" htmlFor="customCheck1">
                Remember me
              </label>
            </div>
          </div> */}

         

          <button
            type="submit"
            className="mt-5 btn btn-lg btn-block"
            id="login-button"
          >
            Reset Password
          </button>

          {/* <p className="forgot-password text-right">
            Forgot <a href="#">password?</a>
          </p> */}



        </div>
      </form>
    </Fragment>
  );
};
export default Forgotpassword;
