import React, {useState, useEffect} from 'react'
import logo from '../Assets/pnslogov2.png';
import badge from '../Assets/badge.png';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from 'react-icons/ai';
import '../Pages/Navbar.css';
import { Route, useNavigate, Link} from 'react-router-dom';
import { IconContext } from 'react-icons';

function Navbar() {

  const check_admin = () => {
    if (localStorage.getItem("admin") == "1") {
      return true;
    } else {
      return false;
    }
  };

  const LogOut = async () => {
    try {
      //const response = await fetch(`${localStorage.getItem("url")}/api/logout`);

      localStorage.clear();
       
      
      window.location = "../";
      
    } catch (err) {
      console.log(err.message);
    }
  };

  const navigate = useNavigate();

    const navigateDashboard = () => {
      navigate('/');
    };

  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)

  useEffect(() => {
   
  }, []);
  if(check_admin()){
    return (
      <div className="navbar_container">
        <div className="navbar_content">
          <div>
            <button className="nav_logo" onClick={navigateDashboard}>
              <span className="nav_icon"><img src={logo}/></span>
            </button>
          </div>
          <div className="navbar_container2">
            <IconContext.Provider value={{ color: '#0a0a0a' }}>
            <div className="navbar">
              <Link to="#" className='menu-bars'>
                <FaIcons.FaBars onClick={showSidebar}/>
              </Link>
            </div>
            <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
              <ul className='nav-menu-items'onClick={showSidebar}>
                <li className="navbar-toggle">
                  <Link to="#" className='menu-bars'>
                    <AiIcons.AiOutlineClose/>
                  </Link>
                </li>
                <li className="nav-text">
                  <a href="/Dashboard" className="nav-link">Dashboard</a>
                </li>
                <li className="nav-text">
                  <a href="/induction" className="nav-link">Induction</a>
                </li>
                <li className="nav-text">
                  <a href="/assessments" className="nav-link">Assessments</a>
                </li>
                <li className="nav-text">
                  <a href="/register" className="nav-link">Register</a>
                </li>
                <li className="nav-text">
                  <a href="/database" className="nav-link">Database</a>
                </li>
                <li className="nav-text">
                  <a href="/" className="nav-link" onClick={LogOut}>Logout</a>
                </li>
              </ul>
            </nav>
            </IconContext.Provider>
            <img src={badge} />
          </div>
        </div>
      </div>
    )

  }else{

    return (
      <div className="navbar_container">
        <div className="navbar_content">
          <div>
            <button className="nav_logo" onClick={navigateDashboard}>
              <span className="nav_icon"><img src={logo}/></span>
            </button>
          </div>
          <div className="navbar_container2">
            <IconContext.Provider value={{ color: '#0a0a0a' }}>
            <div className="navbar">
              <Link to="#" className='menu-bars'>
                <FaIcons.FaBars onClick={showSidebar}/>
              </Link>
            </div>
            <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
              <ul className='nav-menu-items'onClick={showSidebar}>
                <li className="navbar-toggle">
                  <Link to="#" className='menu-bars'>
                    <AiIcons.AiOutlineClose/>
                  </Link>
                </li>
                <li className="nav-text">
                  <a href="/" className="nav-link">Dashboard</a>
                </li>
                <li className="nav-text">
                  <a href="/induction" className="nav-link">Induction</a>
                </li>
                <li className="nav-text">
                  <a href="/assessments" className="nav-link">Assessments</a>
                </li>
                <li className="nav-text">
                  <a href="/" className="nav-link" onClick={LogOut}>Logout</a>
                </li>
              </ul>
            </nav>
            </IconContext.Provider>
            <img src={badge} />
          </div>
        </div>
      </div>
    )
  }
  
}

export default Navbar