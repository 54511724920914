import React from 'react'
import '../Pages/Assessments.css';
import img1 from '../Assets/tamateastore.jpg';
import {Routes, Route, useNavigate} from 'react-router-dom';

function Assessments() {
    const navigate = useNavigate();

    const navigateDashboard = () => {
      navigate('/');
    };

    const navigateInduction = () => {
      navigate('/induction');
    };
  
    const navigateHealthSaftey = () => {
      navigate('/healthandsaftey');
    };


  return (
    <div className="assessments_container">
        <div className="assessments_header">
          <h1>ASSESSMENTS</h1>
        </div>
        <div className="assessments_text">
          <p>Welcome to the PAKn'SAVE Tamatea Assessments.</p>
          <p>Please click on an assessment below to get started.</p>
        </div>
        <div className="assessments_quicklinks">
            <button className="assessments_induction" onClick={navigateInduction}>
                <span className="button_text">Induction</span>
            </button>
            <button className="assessments_healthsaftey" onClick={navigateHealthSaftey} >
                <span className="button_text">Health & Saftey</span>
            </button>
        </div>
        <div className="induction_backbutton">
          <button className="induction_back" onClick={navigateDashboard}>
            <span className="button_text">back</span>
          </button>
        </div>
    </div>
  )
}

export default Assessments