import React, { Fragment, useEffect, useState,useRef } from 'react'
import ReactPlayer from 'react-player'
import '../Induction/BodilyFluids.css';
import {Routes, Route, useNavigate, } from 'react-router-dom';
import moment from "moment";
import { getDefaultNormalizer } from "@testing-library/react";
import  sendEmail2  from '../Sendform/sendHandSform';
import { CSVLink } from "react-csv";
import { FaStoreAltSlash } from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import PulseLoader from 'react-spinners/PulseLoader';

function BodilyFluids() {
  const today = moment().format("YYYY-MM-DD");
  const [date, setDate] = useState(today);
  const [data, setData] = useState([]);
  const [forms, setForm] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [store, setStore] = useState([]);
  const [name, setName] = useState("");
  const [radio1, setRadio1] = useState("");
  const [radio2, setRadio2] = useState("");
  const [radio3, setRadio3] = useState("");
  const [radios, setRadios] = useState("");
  const [form_id, set_form_id] = useState("");
  const navigate = useNavigate();
  const [formname, setFormname] = useState("");
  const first_name = localStorage.getItem("first_name");
  const last_name = localStorage.getItem("last_name");
  const [question1, setQuestion1] = useState("I have watched and understand the details of the clean up of Bodily Fluids Store Operating Procedure");
  const [answer, setAnswer1] = useState("Yes");
  const [question2, setQuestion2] = useState("I understand all of the safety Requirements of the Clean-Up of Bodily Fluids Store Operating Procedure");
  const [answer2, setAnswer2] = useState("Yes");
  const [question3, setQuestion3] = useState("What personal protective equipement (PPE) must be worn when cleaning up bodily fluid spills?");


  const [answer3, setAnswer3] = useState("Disposable apron , mask, eye protection and disposable latex gloves");
  const [question4, setQuestion4] = useState("What must you do as soon as a Bodily Fluid Spill is discovered?");
  const [answer4, setAnswer4] = useState("Isolate it quickly and make sure someone stays with the spill");
  const [question5, setQuestion5] = useState("What warning sign do you need to put out on the floor?");
  const [answer5, setAnswer5] = useState("Wet floor");
  const [question6, setQuestion6] = useState("What is the correct order for cleaning up the spill?");
  const [answer6, setAnswer6] = useState("Absorbent powder, degreaser, sanitiser");
  const [question7, setQuestion7] = useState("How do you know what to reload on the trolley before putting it away?");
  const [answer7, setAnswer7] = useState("Check the contents list");

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  const navigateInduction = () => {
    navigate('/induction');
  };

  const getForms = async (id) => {
    var form_id = id;
    var form_array_number = id - 1;
    var jsonData2 = {};
    try {
      const response2 = await fetch(
        `${localStorage.getItem("url")}/api/forms/${id}`
      );
      jsonData2 = await response2.json();
      
    } catch (err) {
     
      console.log(err.message);
    }

    setForm(jsonData2);
    set_form_id(id);
    setFormname(jsonData2[0].name)
    

  };


  const getQuestions = async (id) =>{

    var form_id = id;
    var form_array_number = id - 1;
    var jsonData2 = {};
    try {
      const response2 = await fetch(
        `${localStorage.getItem("url")}/api/questions/${id}`
      );
      jsonData2 = await response2.json();
     
    } catch (err) {
     
      console.log(err.message);
    }

    jsonData2.forEach((entry) => {
      if(!questions.includes(entry.question_id)){
      
        questions.push(entry.question_id)
      }
      
    })

  };

    const form_submit = async (e) =>{

      e.preventDefault();
      setActive(true);
      if(validateInputs() == true){
     
        try{
    
          const id = form_id;
          const user_id = localStorage.getItem("user_id")

          const body ={id,user_id,date}
          const response = await fetch(
          
            `${localStorage.getItem("url")}/api/completeForm`,
            {
              method: "POST",
              headers: { "content-type": "application/json" },
              body: JSON.stringify(body),
            }
          );
          sendEmail2(formname,first_name,last_name,question1,answer,question2,answer2,question3,answer3,question4,answer4,question5,answer5,question6,answer6,question7,answer7);
          
          
        
          
      } catch (err) {
        console.log(err.message);
      }
      
    }
  }
  


    

  useEffect(()  => {

getForms(4);
 getQuestions(4);

    }, []);

  

    const validateInputs = ()  =>  {
      const error = [];
      const blankValues = [];
   
      const group_1 = (document.getElementsByName(questions[0]))
      const group_2 = (document.getElementsByName(questions[1]))
      const group_3 = (document.getElementsByName(questions[2]))
      const group_4 = (document.getElementsByName(questions[3]))
      const group_5 = (document.getElementsByName(questions[4]))
      const group_6 = (document.getElementsByName(questions[5]))
      const group_7 = (document.getElementsByName(questions[6]))
     

     group_1.forEach((entry) => {
 
     
      
           if(entry.checked == true) {
             if(entry.attributes.value.value == "yes"){
           
       
              return true;
             }else {
               if (error.length != 0) {
                 if (
                   !error.includes(
                     document.getElementById(entry.id)
                   )
                 ) {
                   error.push(
                     document.getElementById(entry.id)
                   )
                 }
               } else {
                 error.push(document.getElementById(entry.id))
               }
             }
           }else{
             blankValues.push(document.getElementById(entry.id))
           }
         });
       
         group_2.forEach((en) => {
 
     
       
    
           if(en.checked == true) {
            
             if(en.attributes.value.value == "yes"){
            
              return true;
             }else {
               if (error.length != 0) {
                 if (
                   !error.includes(
                     document.getElementById(en.id)
                   )
                 ) {
                   error.push(
                     document.getElementById(en.id)
                   )
                 }
               } else {
                 error.push(document.getElementById(en.id))
               }
             }
           }else{
             blankValues.push(document.getElementById(en.id))
           }
         });
       
     
       
         group_3.forEach((ent) => {
 
     
       
          
           if(ent.checked == true) {
            
             if(ent.attributes.value.value == "Disposable apron , mask, eye protection and disposable latex gloves"){
        
              return true;
             }else {
               if (error.length != 0) {
                 if (
                   !error.includes(
                     document.getElementById(ent.id)
                   )
                 ) {
                   error.push(
                     document.getElementById(ent.id)
                   )
                 }
               } else {
                 error.push(document.getElementById(ent.id))
               }
             }
           }else{
         
             blankValues.push(document.getElementById(ent.id))
           }
         });
         group_4.forEach((ent) => {
 
     
       
          
          if(ent.checked == true) {
           
            if(ent.attributes.value.value == "Isolate it quickly and make sure someone stays with the spill"){
       
             return true;
            }else {
              if (error.length != 0) {
                if (
                  !error.includes(
                    document.getElementById(ent.id)
                  )
                ) {
                  error.push(
                    document.getElementById(ent.id)
                  )
                }
              } else {
                error.push(document.getElementById(ent.id))
              }
            }
          }else{
        
            blankValues.push(document.getElementById(ent.id))
          }
        });

        group_5.forEach((ent) => {
 
     
       
          
          if(ent.checked == true) {
           
            if(ent.attributes.value.value == "Wet Floor"){
       
             return true;
            }else {
              if (error.length != 0) {
                if (
                  !error.includes(
                    document.getElementById(ent.id)
                  )
                ) {
                  error.push(
                    document.getElementById(ent.id)
                  )
                }
              } else {
                error.push(document.getElementById(ent.id))
              }
            }
          }else{
        
            blankValues.push(document.getElementById(ent.id))
          }
        });
   
        group_6.forEach((ent) => {
 
     
       
          
          if(ent.checked == true) {
           
            if(ent.attributes.value.value == "Absorbent powder, degreaser, sanitiser"){
       
             return true;
            }else {
              if (error.length != 0) {
                if (
                  !error.includes(
                    document.getElementById(ent.id)
                  )
                ) {
                  error.push(
                    document.getElementById(ent.id)
                  )
                }
              } else {
                error.push(document.getElementById(ent.id))
              }
            }
          }else{
        
            blankValues.push(document.getElementById(ent.id))
          }
        });

        group_7.forEach((ent) => {
 
     
       
          
          if(ent.checked == true) {
           
            if(ent.attributes.value.value == "Check the contents list"){
       
             return true;
            }else {
              if (error.length != 0) {
                if (
                  !error.includes(
                    document.getElementById(ent.id)
                  )
                ) {
                  error.push(
                    document.getElementById(ent.id)
                  )
                }
              } else {
                error.push(document.getElementById(ent.id))
              }
            }
          }else{
        
            blankValues.push(document.getElementById(ent.id))
          }
        });
 
         if (error.length != 0) {
       
     
           var error_list = "";
           error.forEach((errors) => {
             error_list += errors + "\n";
             console.log(errors)
           });
           const retVal =  window.alert("One or more answers are incorrect "    
           );
           setActive(false);
           return false
          
        
        
            
           
         }else if(blankValues.length > 13){
           const retVal =  window.alert("You must answer all questions");
           setActive(false);
           return false
         }else{
        
           
        
           return true
        
            
           
         }
   }
 
   
 


  return (
    <div className="bodilyfluids_container">
            <div className={isActive ? 'loader_overlay': 'loader_overlay_hidden'}>
        <PulseLoader color="#ffffff" />
      </div>
      <div className="bodilyfluids_header">
        <h1>BODILY FLUIDS</h1>
      </div>
      <div className="bodilyfluids_video-wrapper">
        <ReactPlayer
         url='https://youtu.be/0PwXu4own3Y' 
         controls='true' 
         width="100%"
         height="100%"
         />
      </div>
      <div className="bodilyfluids_form">
    

        <form id="all_input" onSubmit={form_submit}>
          <table>
          {forms.map((r) => (
            <tr key={r.id}>
              <td className="pnsform" ><label className="pnsformlabel">{r.question_text}</label>
                <div className="pnsfrominput">
                  {
                    r.AllTheAnswers.split( ':' ).map( ( item ) => <> 
                    <input
                      name={r.question_id} 
                      id={r.question_id} 
                      type="radio"  
                      value={item}  

                  
                    />
                    <label className="questionlabel" for={r.question_id}>{ item }</label> </>) }
                  </div>
                  </td>
                </tr>
              ))} 
                    
          </table>
                      
                
                 
                 
         
        </form>
        <button onClick={form_submit}>Submit</button>
      </div>
      <div className="form_backbutton">
          <button className="form_back" onClick={navigateInduction}>
            <span className="form_text">back</span>
          </button>
      </div>
    </div>
  )
}

export default BodilyFluids;
