import React,{useEffect, useState }from 'react'
import {Routes, Route, useNavigate, } from 'react-router-dom';
import '../Induction/Induction.css';

function Induction() {
  const user_id = localStorage.getItem("user_id")
  const [forms, setForm] = useState([]);

    const navigate = useNavigate();

    const navigateAssessments = () => {
      navigate('/assessments');
    };

    const navigateBodilyFluids = () => {
      navigate('/induction-bodilyfluids');
    };
  
    const navigateBulkChillers = () => {
      navigate('/induction-bulkchillers');
    };

    const navigateForkliftReach = () => {
      navigate('/induction-forkliftreachtrucks');
    };

    const navigateProductSpills = () => {
      navigate('/induction-productspills');
    };

    const navigateRecordingCredits = () => {
      navigate('/induction-recordingcredits');
    };

    const navigateShareOP = () => {
      navigate('/induction-sharedop');
    };

    const getCompletedForms = async () => {
      

      var jsonData2 = {};
      try {
        const response2 = await fetch(
          `${localStorage.getItem("url")}/api/UsersCompletedForms/${user_id}`
        );
        jsonData2 = await response2.json();
          console.log(jsonData2)
      } catch (err) {
       
        console.log(err.message);
      }
  
      setForm(jsonData2);
    
 
      CheckCompletedForms(jsonData2);
      
      
  
    };
    const CheckCompletedForms = async (forms) => {

    
      forms.forEach((form) => {
        const completed_form = document.getElementById(form.form_id)
        console.log(completed_form)
        console.log(form)
        if(form.form_id == completed_form.id){
          completed_form.classList.add("green");
        }else{
          return
        }

      })
    }

    
    const sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

  useEffect(()  =>  {

    getCompletedForms(user_id);

    
        }, []);


  return (
    <div className="induction_container">
        <div className="induction_header">
          <h1>INDUCTION</h1>
        </div>
        <div className="induction_text">
          <p>Welcome to the PAKn'SAVE Tamatea Induction.</p>
          <p>Please click on an induction below to get started.</p>
        </div>
        <div className="induction_quicklinks">
            <button id="4" className="induction_bodilyfluids"  onClick={navigateBodilyFluids}>
                <span  className="button_text">Bodily Fluids</span>
            </button>
            <button id="9" className="induction_bulkchillers"   onClick={navigateBulkChillers}>
                <span  className="button_text">Bulk Chillers</span>
            </button>
            <button id="8" className="induction_forklift"   onClick={navigateForkliftReach}>
                <span  className="button_text">Forklift & Reach Trucks</span>
            </button>
            <button id="5"  className="induction_productspills"   onClick={navigateProductSpills}>
                <span className="button_text">Product Spills</span>
            </button>
            <button id="6" className="induction_credits"  onClick={navigateRecordingCredits}>
                <span  className="button_text">Recording Credits</span>
            </button>
            <button id="7" className="induction_sharedop"   onClick={navigateShareOP}>
                <span  className="button_text">Shared Operating Zones</span>
            </button>
        </div>
        <div className="induction_backbutton">
          <button className="induction_back" onClick={navigateAssessments}>
            <span className="button_text">back</span>
          </button>
        </div>
    </div>
  )
}

export default Induction