import React, {useState} from 'react'
import logo from '../../Assets/pnslogov2.png';
import badge from '../../Assets/badge.png';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from 'react-icons/ai';
import '../../Pages/Navbar.css';
import { Route, useNavigate, Link} from 'react-router-dom';
import { IconContext } from 'react-icons';

function Login_Navbar() {
  const navigate = useNavigate();

    const navigateDashboard = () => {
      navigate('/');
    };

  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)

  return (
    <div className="navbar_container">
      <div className="navbar_content">
        <div>
          <button className="nav_logo" onClick={navigateDashboard}>
            <span className="nav_icon"><img src={logo}/></span>
          </button>
        </div>
        <div className="navbar_container2">
          <IconContext.Provider value={{ color: '#0a0a0a' }}>
          
          <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
            <ul className='nav-menu-items'onClick={showSidebar}>
              <li className="navbar-toggle">
                <Link to="#" className='menu-bars'>
                  <AiIcons.AiOutlineClose/>
                </Link>
              </li>
              <li className="nav-text">
                <a href="/Dashboard" className="nav-link">Dashboard</a>
              </li>
              <li className="nav-text">
                <a href="/induction" className="nav-link">Induction</a>
              </li>
              <li className="nav-text">
                <a href="/assessments" className="nav-link">Assessments</a>
              </li>
             
            </ul>
          </nav>
          </IconContext.Provider>
          <img src={badge} />
        </div>
      </div>
    </div>
  )
}

export default Login_Navbar;