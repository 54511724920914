import React,{useState, useEffect} from 'react';
import {Routes, Route, useNavigate, } from 'react-router-dom';
import has from '../../Assets/has.pdf';
import './healthsafety.css';

import ReactPlayer from 'react-player'


import moment from "moment";
import { getDefaultNormalizer } from "@testing-library/react";
import  sendEmail  from '../Sendform/sendform';
import { CSVLink } from "react-csv";
import { FaStoreAltSlash } from 'react-icons/fa';
import sendEmail2 from '../Sendform/sendHandSform';


function HealthSaftey() {
  const today = moment().format("YYYY-MM-DD");
  const [date, setDate] = useState(today);
  const [forms, setForm] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [form_id, set_form_id] = useState("");
  const [formname, setFormname] = useState("");
  const first_name = localStorage.getItem("first_name");
  const last_name = localStorage.getItem("last_name");
  const [answer_1,setAnswer1] = useState("");
  const [answer_2, setAnswer2] = useState("");
  const [answer_3,setAnswer3 ]= useState("");
  const [answer_4, setAnswer4 ]= useState("");
  const [answer_5, setAnswer5] = useState("");
  const [answer_6,setAnswer6 ]= useState("");
  const [answer_7,setAnswer7]  = useState("");
  const [answer_8, setAnswer8 ]= useState("");      
  const [answer_9, setAnswer9 ]= useState("");
  const [answer_10,setAnswer10]  = useState("");      
  const [answer_11, setAnswer11] = useState("");
  const [answer_12,setAnswer12 ] = useState("");
  const [question1,setQuestion1] = useState("1. Who is responsible for my safety?");
  const [question2,setQuestion2] = useState("2. Where would you find STORE Health and Safety Policies?");
  const [question3,setQuestion3] = useState("3. What types of Drug and Alchohol testing are performed on out site?");
  const [question4,setQuestion4] = useState("4. Which site entry doors are prohibited from use by both the warehouse workers and the public?");
  const [question5,setQuestion5] = useState("5. Where is the main notice board?");
  const [question6,setQuestion6] = useState("6. What two items of PPE must be worn on our site?");
  const [question7,setQuestion7] = useState("7. Where are the first aid facilities closest to my typical work location");
  const [question8,setQuestion8] = useState("8. How are our Fire Wardens identified in assembly areas?");
  const [question9,setQuestion9] = useState("9. Why was the Health and Safety Working Group established?");
  const [question10,setQuestion10] = useState("10. How do you report an accident, incident or near hit?");
  const [question11,setQuestion11] = useState("11. What is a hazard?");
  const [question12,setQuestion12] = useState("12. What should you do when you feel the first signs of discomfort?");


  const navigate = useNavigate();

  const navigateAssessments = () => {
    navigate('/assessments');
  };
  

  const getForms = async (id) => {
    var form_id = id;
    var form_array_number = id - 1;
    var jsonData2 = {};
    try {
      const response2 = await fetch(
        `${localStorage.getItem("url")}/api/healthandsafetyform`
      );
      jsonData2 = await response2.json();
        console.log(jsonData2)
    } catch (err) {
     
      console.log(err.message);
    }

    setForm(jsonData2);
    set_form_id(jsonData2[0].id);
    setFormname(jsonData2[0].name)
   
    
    

  };



    const form_submit = async (e) =>{
      e.preventDefault();
    console.log(questions.toLocaleString());
      if(validateInputs() == true){
     
        try{
    
             
      const user_id = localStorage.getItem("user_id")
  
 

          const body ={user_id,date,answer_1,answer_2,answer_3,answer_4,answer_5,answer_6,answer_7,answer_8,answer_9,answer_10,answer_11,answer_12}
          const response = await fetch(
          
            `${localStorage.getItem("url")}/api/completeHealthAndSafetyForm`,
            {
              method: "POST",
              headers: { "content-type": "application/json" },
              body: JSON.stringify(body),
            }
          );
            
          sendEmail2(formname,first_name,last_name,question1,question2,question3,question4,question5,question6,question7,question8,question9,question10,question11,question12,answer_1,answer_2,answer_3,answer_4,answer_5,answer_6,answer_7,answer_8,answer_9,answer_10,answer_11,answer_12);
        
      } catch (err) {
        console.log(err.message);
      }
      
    }
  }

    
  const validateInputs = ()  =>  {
    const error = [];
    const blankValues = [];
 
    const group_1 = (document.getElementsByName("input"))
 
   

   group_1.forEach((entry) => {

    
         if( entry.value.length > 0) {
       
     
           
      
             
         }else{
           blankValues.push(document.getElementById(entry.id))
         }
       });
     
 

      
     
         
       if(blankValues.length > 0){
         const retVal =  window.alert("You must answer all questions");
         return false
       }else{
      
         
       
         return true
      
          
         
       }
 }

 const setValue = (id, value) => {
  if(id == 22){
    setAnswer1(value)
 
  }else if(id == 23){
    setAnswer2(value)
    
  }else if(id == 24){
    setAnswer3(value)
   
  }else if(id == 25){
    setAnswer4(value)
 
  }else if(id == 26){
    setAnswer5(value)
 
  }else if(id == 27){
    setAnswer6(value)
 
  }else if(id == 28){
    setAnswer7(value)
   
  }else if(id == 29){
    setAnswer8(value)
   
  }else if(id == 30){
    setAnswer9(value)
  }else if(id == 31){
    setAnswer10(value)

  }else if(id == 32){
    setAnswer11(value)
    
  }
  else if(id == 33){
    setAnswer12(value)
   
  }
}


   


  useEffect(()  => {

getForms();


    }, []);

  return (
    <div className="healthsafety_container">
      <div className="healthsafety_header">
        <h1>HEALTH & SAFTEY</h1>
      </div>
      <div className="healthsafety_text">
        <p>Please read the health & safety booklet below then anwser the questions.</p>
        <a href={has}><button className="button_has">Health & Safety</button></a>
      </div>
      <div className="healthsafety_form-container">
        <div className="healthsafety_form">
          <form id="all_input" onSubmit={form_submit}>
              <table className="form_table">
              {forms.map((r) => (
                <tr key={r.form_question_id}>
                  <td className="pnsform" ><label className="pnsformlabel"> {r.form_question_id - 21}. {r.question_text}</label>
                    <input className="healthsafetyinput" name="input" onChange={(e) => setValue(r.form_question_id, e.target.value)} id={r.form_question_id}></input>
                      </td>
                    </tr>
                  ))} 
                        
              </table>
                          
                    
                    
                    
            
            </form>
            <button className="healthsafetysubmit" onClick={form_submit}>Submit</button>
          </div>
        </div>
      <div className="healthsafety_back">
        <button className="healthsafety_back-button"  onClick={navigateAssessments}>
          <span  className="healthsafety_text">back</span>
        </button>
      </div>
    </div>
  )
}

export default HealthSaftey