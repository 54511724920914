import React from 'react';
import {Routes, Route, useNavigate, } from 'react-router-dom';
import './contactinstore.css';

function ContactInStore() {
    const navigate = useNavigate();

    const navigateContact = () => {
      navigate('/contact');
    };

  return (
    <div className="contactin_container">
        <div className="contactin_header">
            <h1>CONTACT IN-STORE</h1>
        </div>
        <div className="contactintext">
            <p>Below is a list of extensions & speed dials to be used when you are calling within the store.</p>
        </div>
        <div className="contactin_tables">
            <table className='department'>
                <tr>
                    <th>Department</th>
                    <th>Extn</th>
                </tr>
                <tr>
                    <td>Checkout Service Desk</td>
                    <td>701</td>
                </tr>
                <tr>
                    <td>Admin</td>
                    <td>702</td>
                </tr>
                <tr>
                    <td>Pricing Inventory</td>
                    <td>703</td>
                </tr>
                <tr>
                    <td>Online - Floor</td>
                    <td>704</td>
                </tr>
                <tr>
                    <td>Inwards Goods</td>
                    <td>706</td>
                </tr>
                <tr>
                    <td>Grocery Dept 1</td>
                    <td>607</td>
                </tr>
                <tr>
                    <td>Grocery Dept 2</td>
                    <td>608</td>
                </tr>
                <tr>
                    <td>Frozen Dept</td>
                    <td>709</td>
                </tr>
                <tr>
                    <td>Chilled Dept</td>
                    <td>710</td>
                </tr>
                <tr>
                    <td>Produce Dept</td>
                    <td>711</td>
                </tr>
                <tr>
                    <td>Seafood Dept</td>
                    <td>712</td>
                </tr>
                <tr>
                    <td>Butchery Dept</td>
                    <td>713</td>
                </tr>
                <tr>
                    <td>Deli Dept</td>
                    <td>715</td>
                </tr>
                <tr>
                    <td>Jasmine - Cashier/Compliance</td>
                    <td>712</td>
                </tr>
                <tr>
                    <td>Admin Desk / Payroll</td>
                    <td>717</td>
                </tr>
                <tr>
                    <td>Cash Room</td>
                    <td>718</td>
                </tr>
                <tr>
                    <td>Coffe Cart</td>
                    <td>719</td>
                </tr>
                <tr>
                    <td>Lunch Room</td>
                    <td>721</td>
                </tr>
            </table>
            <table className="person">
                <tr>
                    <th>Person</th>
                    <th>Speed Dial</th>
                </tr>
                <tr>
                    <td>Andrew Graney</td>
                    <td>200</td>
                </tr>
                <tr>
                    <td>Andy Eriksen</td>
                    <td>201</td>
                </tr>
                <tr>
                    <td>Belinda Green</td>
                    <td>202</td>
                </tr>
                <tr>
                    <td>Jo Milne</td>
                    <td>203</td>
                </tr>
                <tr>
                    <td>Duty Managers</td>
                    <td>204</td>
                </tr>
                <tr>
                    <td>Virginia Walker</td>
                    <td>205</td>
                </tr>
                <tr>
                    <td>Paul James</td>
                    <td>206</td>
                </tr>
                <tr>
                    <td>SPARE-</td>
                    <td>207</td>
                </tr>
                <tr>
                    <td>Craig McDermott</td>
                    <td>208</td>
                </tr>
                <tr>
                    <td>Cindy Calitz</td>
                    <td>209</td>
                </tr>
                <tr>
                    <td>Richard Cameron</td>
                    <td>210</td>
                </tr>
                <tr>
                    <td>Bridget Matheson</td>
                    <td>211</td>
                </tr>
                <tr>
                    <td>Pip James</td>
                    <td>212</td>
                </tr>
                <tr>
                    <td>Ronaye Hayes</td>
                    <td>213</td>
                </tr>
                <tr>
                    <td>Sherree James</td>
                    <td>214</td>
                </tr>
                <tr>
                    <td>Sarndra Gordon</td>
                    <td>215</td>
                </tr>
                <tr>
                    <td>Hannah Young</td>
                    <td>216</td>
                </tr>
                <tr>
                    <td>jason Schilling</td>
                    <td>217</td>
                </tr>
                <tr>
                    <td>Security - Loss Prevention Officer</td>
                    <td>310</td>
                </tr>
                <tr>
                    <td>Juan Raubenheimer</td>
                    <td>218</td>
                </tr>
                <tr>
                    <td>Silke Kamper</td>
                    <td>219</td>
                </tr>
            </table>
        </div>
        <div className="contactin_back">
            <button className="contact_back-button"  onClick={navigateContact}>
                <span  className="button_text">back</span>
            </button>
      </div>
    </div>
  )
}

export default ContactInStore