import React from 'react';
import {Routes, Route, useNavigate, } from 'react-router-dom';
import './contact.css';

function Contact() {
  const navigate = useNavigate();

  const navigateContactOut = () => {
    navigate('/contact-outofstore');
  };

  const navigateContactIn = () => {
    navigate('/contact-instore');
  };

  const navigateDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <div className="contact_container">
      <div className="contact_header">
        <h1>CONTACT</h1>
      </div>
      <div className="contact_quicklinks">
        <button className="contact_button-in"  onClick={navigateContactIn}>
          <span  className="button_text">In-Store</span>
        </button>
        <button className="contact_button-out"  onClick={navigateContactOut}>
          <span  className="button_text">Out of Store</span>
        </button>
      </div>
      <div className="contactin_back">
        <button className="contact_back-button"  onClick={navigateDashboard}>
          <span  className="button_text">back</span>
        </button>
      </div>

    </div>
  )
}

export default Contact