import React, { Fragment, useState, Component } from "react";
//Copyright © 2011-2015, Paul Vorbach.
//Copyright © 2009, Jeff Mott.
import {Routes, Route, useNavigate} from 'react-router-dom';
import md5 from "md5";
import sha1 from "sha1";
import Errorpage from "../Errorpage";
import './register.css'

const Register = () => {
  const navigate = useNavigate();

  const navigateDashboard = () => {
    navigate('/');
  };

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmitForm = async (e) => {
    e.preventDefault();

    try {
      const hash_password = md5(sha1(password));
      const body = { first_name, last_name, email, hash_password };
      const response = await fetch(
        `${localStorage.getItem("url")}/api/register`,
        {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(body),
        }
      );

      window.alert("User " +first_name + " has been added")
    } catch (err) {
      console.log(err.message);
    }
  };
  
  const check_admin = () => {
    if (localStorage.getItem("admin") == "1") {
      return true;
    } else {
      return false;
    }
  };

if(check_admin()){
  return (
    <Fragment>
      <div className="register_container">
        <div className="register_header">
          <h1>REGISTER</h1>
        </div>
        <div className="register_text">
          <p>Enter in employee details below to register them for the team site.</p>
        </div>
        <form className="register_form" id="register" onSubmit={onSubmitForm} methgo="post">
          <div id="form-body">
            <div className="form-group">
              <label>First name:</label>
              <input
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                className="form-control"
                placeholder="First name"
              />
            </div>
            <div className="form-group">
              <label>Last name:</label>
              <input
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Last name"
              />
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="form-control"
                placeholder="Enter email"
              />
            </div>
            <div className="form-group">
              <label>Password:</label>
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                className="form-control"
                placeholder="Enter password"
              />
            </div>
            <button
              type="submit"
              className="mt-5 btn btn-dark btn-lg btn-block"
              id="login-button"
            >
              Register Member
            </button>
            {/* <a type="" href="/login" className="btn btn-dark btn-lg btn-block">
              login
            </a> */}
            {/* <p className="forgot-password text-right">
              Already registered <a href="#">log in?</a>
            </p>{" "} */}
          </div>
        </form>
        <div className="induction_backbutton">
          <button className="induction_back" onClick={navigateDashboard}>
            <span className="button_text">back</span>
          </button>
        </div>
      </div>
    </Fragment>
  );
}else{
  <Errorpage></Errorpage>
}
 
};
export default Register;
