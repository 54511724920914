import React from 'react'

function footer() {
  return (
    <div>
        <div>
            <a href="#andg">Andrew Graney</a>
        </div>
            <div id="andg" class="overlay">
            <div class="popup">
                <h2>Andrew Graney</h2>
                <a class="close" href="#">&times;</a>
            <div class="content">
                0274449042 <br/> Andrew.Graney@foodstuffs.co.nz
            </div>
            </div>
            </div>
        
    </div>

  )
}

export default footer