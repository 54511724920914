
import '../Pages/Dashboard.css';
import img1 from '../Assets/tamateastore.jpg';

import React, { Fragment, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'

import {Routes, Route, useNavigate, } from 'react-router-dom';
import moment from "moment";

import handbook from '../Assets/handbook.pdf';

import { FaStoreAltSlash, FaWindows } from 'react-icons/fa';

function Dashboard() {
  const navigate = useNavigate();

    const navigateInduction = () => {
      navigate('/induction');
    };
  
    const navigateContact = () => {
      navigate('/contact');
    };

    const navigateAssessments = () => {
        navigate('/assessments');
      };

    const facebookGroup = () => {

        window.open("https://www.facebook.com/groups/2404315406260119/");
    
    }

  if(localStorage.getItem("first_time") == 1){
   return  window.location = "/forgotpassword";
  }else{

    

  return (
    <div className="dashboard_container">
      <div className="dashboard_welcome-header">
        <h1>DASHBOARD</h1>
      </div>
      <div className="dashboard_welcome">
        <div className="bodilyfluids_video-wrapper">
          <ReactPlayer
          url='https://vimeo.com/735342685' 
          controls='true' 
          width="100%"
          height="100%"
          />
        </div>
        <p>Please watch the two PAK'nSAVE videos below.</p>
        <div className="dashboard_quicklinks">
          <div className="quicklink">
            <a href="#video1"><button className="button_customer">Customer Promises</button></a>
          </div>
          <div className="quicklink">
          <a href="#video2"><button className="button_value">Store Values</button></a>
          </div>
        </div>
        <div id="video1" class="overlay">
          <div class="popup">
            <a class="close" href="#dashboard">&times;</a>
          <div class="content">
          <div className="bodilyfluids_video-wrapper">
          <ReactPlayer
          url='https://vimeo.com/735342343' 
          controls='true' 
          width="100%"
          height="100%"
          />
        </div>
          </div>
          </div>
        </div>
        <div id="video2" class="overlay">
          <div class="popup">
            <a class="close" href="#dashboard">&times;</a>
          <div class="content">
          <div className="bodilyfluids_video-wrapper">
          <ReactPlayer
          url='https://vimeo.com/735342417' 
          controls='true' 
          width="100%"
          height="100%"
          />
        </div>
          </div>
          </div>
        </div>


        <div id="video1" class="overlay">
          <div class="popup">
            <a class="close" href="#dashboard">&times;</a>
          <div class="content">
          <div className="bodilyfluids_video-wrapper">
          <ReactPlayer
          url='https://vimeo.com/735342343' 
          controls='true' 
          width="100%"
          height="100%"
          />
        </div>
          </div>
          </div>
        </div>
        <div id="video2" class="overlay">
          <div class="popup">
            <a class="close" href="#dashboard">&times;</a>
          <div class="content">
          <div className="bodilyfluids_video-wrapper">
          <ReactPlayer
          url='https://vimeo.com/735342417' 
          controls='true' 
          width="100%"
          height="100%"
          />
        </div>
          </div>
          </div>
        </div>
      </div>
        <div className="dashboard_quicklinks-header">
            <h1>QUICKLINKS</h1>
            
        </div>
        <div className="dashboard_quicklinks">
          <div className="quicklink">
            <button className="button_induction" onClick={navigateInduction}>
              <span className="button_text">Induction</span>
            </button>
          </div>
          <div className="quicklink">
            <button className="button_assessments" onClick={navigateAssessments}>
              <span className="button_text">Assessments</span>
            </button>
          </div>
          <div className="quicklink">
            <button className="button_contact" onClick={navigateContact}>
              <span className="button_text">Contact</span>
            </button>
          </div>
          <div className="quicklink">
            <a href={handbook}><button className="button_handbook">Employee Handbook</button></a>
          </div>
        </div>
        <div className="footer">

        </div>
    </div>
  )
  }

 


    
}


export default Dashboard
