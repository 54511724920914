import React from 'react';
import {Routes, Route, useNavigate, } from 'react-router-dom';
import './contactoutofstore.css';

function ContactOutOfStore() {
  const navigate = useNavigate();

  const navigateContact = () => {
    navigate('/contact');
  };

  return (
    <div className="contactout_container">
      <div className="contactout_header">
        <h1>CONTACT OUT OF STORE</h1>
      </div>
      <div className="contactin_text">
        <p>Below is a list of emmployees and their position, phone number and email.</p>
      </div>
      <div className="contactout_tables">
        <table>
          <tr>
            <th>Name</th>
            <th>Department</th>
          </tr>
          <tr>
            <td>PNS Tamatea</td>
            <td>Main Number</td>
          </tr>
          <tr>
            <td><a href="#andg">Andrew Graney</a></td>
            <td>Owner Operator</td>
          </tr>
          <tr>
            <th>FRESH FOODS</th>
            <td></td>
          </tr>
          <tr>
            <td><a href="#ricc">Richard Cameron</a></td>
            <td>Bakery</td>
          </tr>
          <tr>
            <td><a href="#sarg">Sarndra Gordan</a></td>
            <td>Service Deli</td>
          </tr>
          <tr>
            <td><a href="#pauj">Paul James</a></td>
            <td>Butchery</td>
          </tr>
          <tr>
            <td><a href="#phij">Philippa James</a></td>
            <td>Seafood</td>
          </tr>
          <tr>
            <td><a href="#cram">Craig McDermott</a></td>
            <td>Produce</td>
          </tr>
          <tr>
            <th>DRY GOODS</th>
            <td></td>
          </tr>
          <tr>
            <td><a href="#ande">Andrew Eriksen</a></td>
            <td>Dry Goods - Manager</td>
          </tr>
          <tr>
            <td><a href="#cinc">Cindy Calitz</a></td>
            <td>Grocery - Snr Category Buyer</td>
          </tr>
          <tr>
            <td><a href="#trol">Troy Leitch</a></td>
            <td>Grocery - Category Buyer</td>
          </tr>
          <tr>
            <td><a href="#belg">Belinda Green</a></td>
            <td>Grocery Buyer</td>
          </tr>
          <tr>
            <td><a href="#jass">Jason Schilling</a></td>
            <td>Grocery Buyer</td>
          </tr>
          <tr>
            <td><a href="#shah">Shane Harvey</a></td>
            <td>Grocery - Retail Operations</td>
          </tr>
          <tr>
            <td><a href="#shej">Sherree James</a></td>
            <td>Liquor/Fozen/Chilled</td>
          </tr>
          <tr>
            <td><a href="#nigf">Night Fill Team Leader</a></td>
            <td></td>
          </tr>
          <tr>
            <th>NON-TRADING</th>
            <td></td>
          </tr>
          <tr>
            <td><a href="#silk">Silke Kamper</a></td>
            <td>Inventory/Pricing</td>
          </tr>
          <tr>
            <td><a href="#virw">Virginia Walker</a></td>
            <td>Compliance</td>
          </tr>
          <tr>
            <th>People and Performance / Admin</th>
            <td></td>
          </tr>
          <tr>
            <td><a href="#brim">Bridget Matheson</a></td>
            <td>HR & Admin</td>
          </tr>
          <tr>
            <td><a href="#hany">Hannah Young</a></td>
            <td>HR</td>
          </tr>
          <tr>
            <th>STORE</th>
            <td></td>
          </tr>
          <tr>
            <td><a href="#care">Carey</a></td>
            <td>Duty Managers</td>
          </tr>
          <tr>
            <td><a href="#geof">Geofrey</a></td>
            <td></td>
          </tr>
          <tr>
            <td><a href="#nickp">Nick Potgieter</a></td>
            <td>Loss Provention</td>
          </tr>
        </table>
      </div>
      <div className="contactin_back">
        <button className="contact_back-button"  onClick={navigateContact}>
          <span  className="button_text">back</span>
        </button>
      </div>
      <div id="andg" class="overlay">
	      <div class="popup">
		      <h2>Andrew Graney</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
			    0274449042 <br/> Andrew.Graney@foodstuffs.co.nz
		    </div>
        </div>
      </div>
      <div id="pnst" class="overlay">
	      <div class="popup">
		      <h2>PAK'nSAVE Tamatea</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
          843 3135
		    </div>
        </div>
      </div>
      <div id="ricc" class="overlay">
	      <div class="popup">
		      <h2>Richard Cameron</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
         021825651 <br/> Richard.Cameron2@pns.co.nz
		    </div>
        </div>
      </div>
      <div id="sarg" class="overlay">
	      <div class="popup">
		      <h2>Sarndra Gordan</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
          0272050320 <br/> Sarndra.Gordan@pns.co.nz
		    </div>
        </div>
      </div>
      <div id="pauj" class="overlay">
	      <div class="popup">
		      <h2>Paul James</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
          0272050149 <br/> Paul.James1@pns.co.nz
		    </div>
        </div>
      </div>
      <div id="phij" class="overlay">
	      <div class="popup">
		      <h2>Philippa James</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
          0272880574 <br/> Philippa.James@pns.co.nz
		    </div>
        </div>
      </div>
      <div id="cram" class="overlay">
	      <div class="popup">
		      <h2>Craig McDermott</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
        0274848166 <br/> CraigM@pns.co.nz
		    </div>
        </div>
      </div>
      <div id="ande" class="overlay">
	      <div class="popup">
		      <h2>Andrew Eriksen</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
        0272583345 <br/> Andrew.Eriksen@pns.co.nz
		    </div>
        </div>
      </div>
      <div id="cinc" class="overlay">
	      <div class="popup">
		      <h2>Cindy Calitz</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
        0272566502 <br/> Cindy.Calitz@pns.co.nz
		    </div>
        </div>
      </div>
      <div id="trol" class="overlay">
	      <div class="popup">
		      <h2>Troy Leitch</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
        0272138505 <br/> Jo.Milne1@pns.co.nz
		    </div>
        </div>
      </div>
      <div id="belg" class="overlay">
	      <div class="popup">
		      <h2>Belinda Green</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
        0272236764 <br/> Belinda.Green@pns.co.nz
		    </div>
        </div>
      </div>
      <div id="jass" class="overlay">
	      <div class="popup">
		      <h2>Jason Schilling</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
        0272102887 <br/> Jason.Schilling@pns.co.nz
		    </div>
        </div>
      </div>
      <div id="shah" class="overlay">
	      <div class="popup">
		      <h2>Shane Harvey</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
        0272889534 <br/> Juan.Raubenheimer@pns.co.nz
		    </div>
        </div>
      </div>
      <div id="shej" class="overlay">
	      <div class="popup">
		      <h2>Sherree James</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
        0272901283 <br/> SherreeJ@pns.co.nz
		    </div>
        </div>
      </div>
      <div id="nigf" class="overlay">
	      <div class="popup">
		      <h2>Night Fill Team Leader</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
        0272892473
		    </div>
        </div>
      </div>
      <div id="virw" class="overlay">
	      <div class="popup">
		      <h2>Virginia Walker</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
        0272881539 <br/> Virginia.Walker@pns.co.nz
		    </div>
        </div>
      </div>
      <div id="silk" class="overlay">
	      <div class="popup">
		      <h2>Silke Kamper</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
        0272866570 <br/> Silke.Kamper@pns.co.nz
		    </div>
        </div>
      </div>
      <div id="brim" class="overlay">
	      <div class="popup">
		      <h2>Bridget Matheson</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
        021825181 <br/> Bridget.Matheson@pns.co.nz
		    </div>
        </div>
      </div>
      <div id="hany" class="overlay">
	      <div class="popup">
		      <h2>Hannah Young</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
        0274577974 <br/> Hannah.Young1@pns.co.nz
		    </div>
        </div>
      </div>
      <div id="care" class="overlay">
	      <div class="popup">
		      <h2>Carey</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
        CareyC@pns.co.nz
		    </div>
        </div>
      </div>
      <div id="nicp" class="overlay">
	      <div class="popup">
		      <h2>Nick Potgieter</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
        0274329173 <br/> Nick.Potgieter@pns.co.nz
		    </div>
        </div>
      </div>
      <div id="geof" class="overlay">
	      <div class="popup">
		      <h2>Geofrey</h2>
		      <a class="close" href="#">&times;</a>
		    <div class="content">
        Geofrey.VanDyk@foodstuffs.co.nz
		    </div>
        </div>
      </div>
    </div>
  )
}

export default ContactOutOfStore